<template>
  <div style="position: relative;width: 100%" v-click-outside="onClose">
    <button type="button" :class="clazz" @click="state.show = !state.show"
            style="text-align: left; padding-right: 10px">
      <span v-for="(item, index) in state.selectObjects" v-bind:key="index" class="label label-inline mr-2"
            style="position: relative; font-size: 11px; padding-left: 5px">
        <template v-if="custom">
            <slot name="display" :item="item"></slot>
        </template>
        <template v-else>
          {{item[optionLabel]}}
        </template>
       &nbsp;
        <i class="fa fa-times" style="position: absolute; top: 0.2rem; right: -2px; font-size: 10px"
           @click="remove(item)"></i>
      </span>
      <i class="fa fa-times" v-if="state.selectObjects.length > 0" style="position: absolute; top: 1rem; right: 1.5rem"
         @click="clear"></i>
      <i class="fa fa-angle-down" style="position: absolute; top: 1rem; right: 0.55rem"></i>
    </button>
    <div class="dropdown-menu show" v-if="state.show" style="width: 100%; height: auto; ">
      <div class="dropdown-item"><input class="form-control form-control-sm" placeholder="Search..." @input="search"
                                        type="text" v-model="state.keywordSearch"></div>
      <template v-if="!loading">
        <div class="dropdown-item base-select-option"
             v-for="item in options"
             v-bind:key="item[optionKey]"
             v-bind:class="{'hover': state.selectObjects.includes(item)}"
             @click="onSelect(item)">

          <template v-if="custom">
            <slot name="label" :item="item"></slot>
          </template>
          <template v-else>
            {{item[optionLabel]}}
          </template>
        </div>
      </template>
      <div class="dropdown-item disabled" v-if="options?.length === 0 && !loading">No data</div>
      <div class="dropdown-item disabled" v-if="loading">Loading</div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive, watch} from "vue";
import debounce from "debounce";

export default defineComponent({
  name: "BaseMultiSelect",
  props: {
    clazz: {required: false, default: "form-control form-control-solid form-control-lg fw-bold"},
    modelValue: {},
    selectOption: {required: false, default: {}},
    options: {},
    optionKey: {},
    optionLabel: {},
    loading: {required: false, default: false},
    custom: {required: false, default: false},
    multiSelect: {required: false, default: false}
  },
  setup(props) {
    const state = reactive({
      showIfNull: false,
      keywordSearch: '',
      selectObjects: []
    })
    watch(() => props.modelValue, (cb) => {
      if (cb.length === 0) {
        state.selectObjects = []
      } else {
        if (!props.multiSelect) {
          const selected = props.options.find(item => item[props.optionKey] === cb)
          state.selectObjects = [selected];
        } else {
          cb.forEach(i => {
            const selected = props.options.find(item => item[props.optionKey] === i)
            if (!state.selectObjects.includes(selected)) {
              state.selectObjects.push(selected);
            }
          })
        }
      }
    })
    watch(() => props.options, (cb) => {
      if (props.modelValue && !props.multiSelect) {
        const selected = cb.find(item => item[props.optionKey] === props.modelValue)
        if (selected) state.selectObjects = [selected];
      }
    })
    return {
      state,
    }
  },
  emits: ['update:modelValue', 'search', 'onSelect'],
  methods: {
    onClose() {
      this.state.show = false
    },
    search: debounce(function () {
      this.$emit('search', this.state.keywordSearch)
    }, 500),
    clear() {
      this.$emit('update:modelValue', "")
      this.state.selectObjects = [];
    },
    remove(item) {
      this.state.selectObjects = this.state.selectObjects.filter(val => val !== item)
    },
    onSelect(item) {
      if (this.state.selectObjects.includes(item)) {
        return
      } else {
        if (this.multiSelect) {
          this.state.selectObjects.push(item)
        } else {
          this.state.selectObjects = [item]
        }
      }
      const ids = this.state.selectObjects.map(value => value.id)
      this.$emit('update:modelValue', this.multiSelect ? ids : ids[0])
      this.$emit('onSelect', this.multiSelect ? ids : ids[0])
    }
  }
})
</script>

<style scoped>
.base-select-option:hover {
  cursor: pointer;
}

.dropdown-menu {
  max-height: 250px;
  overflow: auto;
}

.form-control {
  height: auto;
  min-height: calc(1.5em + 1.3rem + 2px);
}

.hover {
  background-color: #EBEDF3;
}
</style>