
import {defineComponent, reactive, ref} from "vue";
import TemplateService from "@/core/services/TemplateService";

export default defineComponent({
  name: "TemplateListSelect",
  props: {
    clazz: {type: String, default: () => 'form-control form-control-solid form-control-lg fw-bold'},
    showCompany: {type: Boolean, default: () => true},
    multiple: {type: Boolean, default: () => false},
    clientId: {type: String, default: () => ''},
    type: {type: String, required: true},
  },
  setup(props) {

    const options = ref<any>([])
    const loading = ref(false);
    const state = reactive<any>({
      show: false,
      selectObjects: []
    })
    const loadOptions = (type, clientId) => {
      loading.value = true
      TemplateService.templateList(type, clientId).then(res => {
        options.value = res;
      }).finally(() => {
        loading.value = false;
      })
    }
    loadOptions(props.type, props.clientId);

    // watch(() => props.modelValue, (cb: any) => {
    //   if (cb.length === 0) {
    //     state.selectObjects = []
    //   } else {
    //     if (!props.multiple) {
    //       const selected = options.value.find(item => item.templates.find(k => k['id'] === cb));
    //       if (selected) {
    //         const user = selected.templates.find(item => item['id'] === cb)
    //         state.selectObjects = [user];
    //       }
    //     } else {
    //       cb.forEach(i => {
    //         const selected = options.value.find(item => item.templates.find(k => k['id'] === i));
    //         if (selected) {
    //           const user = selected.templates.find(item => item['id'] === i)
    //           if (!state.selectObjects.includes(user)) {
    //             state.selectObjects.push(user);
    //           }
    //         }
    //       })
    //     }
    //   }
    // })
    // const loadSelectedItem = () => {
    //   const modelValue = ref<any>(props.modelValue);
    //   if (modelValue.value.length === 0) {
    //     state.selectObjects = []
    //   } else {
    //     if (!props.multiple) {
    //       const selected = options.value.find(item => item.templates.find(k => k['id'] === modelValue.value));
    //       if (selected) {
    //         const user = selected.templates.find(item => item['id'] === modelValue.value)
    //         state.selectObjects = [user];
    //       }
    //     } else {
    //       modelValue.value.forEach(i => {
    //         const selected = options.value.find(item => item.templates.find(k => k['id'] === i));
    //         if (selected) {
    //           const user = selected.templates.find(item => item['id'] === i)
    //           if (!state.selectObjects.includes(user)) {
    //             state.selectObjects.push(user);
    //           }
    //         }
    //       })
    //     }
    //   }
    // }
    return {
      state,
      loading,
      options,
    }
  },
  emits: ['update:modelValue', 'onSelect'],
  methods: {
    onClose() {
      this.state.show = false
    },
    remove(item) {
      this.state.selectObjects = this.state.selectObjects.filter(val => val !== item)
    },
    clear() {
      this.state.selectObjects = [];
    },
    onSelect(item) {
      if (this.state.selectObjects.includes(item)) {
        return
      } else {
        if (this.multiple) {
          this.state.selectObjects.push(item)
        } else {
          this.state.selectObjects = [item]
        }
      }
      this.$emit('onSelect', item)
    }
  }
})
